import React from 'react';
import Gallery from '../components/gallery';
import Head from '../components/head';
import { useStaticQuery, graphql } from 'gatsby';
import { cloudinaryReduce } from '../helpers/cloudinaryReduce';

// eslint-disable-next-line react/display-name
export default () => {
  const data = useStaticQuery(graphql`
    query Interiors {
      allCloudinaryMedia(
        filter: { public_id: { regex: "/interiorsDirectory/" } }
      ) {
        edges {
          node {
            asset_id
            public_id
            secure_url
            width
            height
          }
        }
      }
    }
  `);

  const images = data.allCloudinaryMedia.edges;

  const imagesOutput = cloudinaryReduce(images);

  return (
    <>
      <Head pageTitle="interiors" />
      <Gallery photos={imagesOutput} id="top" />
      );
    </>
  );
};
